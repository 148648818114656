import Vue from 'vue'
import App from './App.vue'
import 'bootstrap/dist/css/bootstrap.min.css'
import VueResource from 'vue-resource'
import VueRouter from 'vue-router'
import Routes from './routes'
import VModal from 'vue-js-modal'

Vue.use(VueResource);
Vue.use(VueRouter);
Vue.use(VModal)
Vue.config.productionTip = false

const router = new VueRouter ({
  routes: Routes
});


new Vue({
  render: h => h(App),
  router: router
}).$mount('#app')
