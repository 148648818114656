<template>
    <div class="container">
        <h1 class="text-center pt-lg-3 pt-3 pb-lg-4">Naše produkty</h1>
        <p class="text-center textProducts">K jižní Moravě mám skutečnou úctu a je pro mě srdeční záležitostí.
          Mou velkou vášní je sbírání starých receptů převážně tady z „jihu“ a
          pečení koláčů a koláčků na které se naše cukrářství specializuje.
        </p>
    </div>
</template>

<script>
    export default {
        name: "ourproducts"
    }
</script>

<style scoped>
    .textProducts {
        font-size: 19px;
    }
</style>