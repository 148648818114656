<template>
  <div class="backgroundProducts">
    <div id="BlockProducts" class="container blockOfProducts">
      <h1 class="text-center pt-lg-3 pb-lg-4">Naše produkty</h1>
      <h6 class="text-center pb-lg-4">Dobrůtky můžete objednávat telefonicky: +420 737 525 600.</h6>
      <div class="row pb-lg-4">
        <div class="col-4-lg offset-lg-3 offset-md-3 offset-sm-2 pb-3 paddingLeft">
          <div v-on:click="selectKindOfProducts(0)" class="btn btn_products">Vše</div>
        </div>
        <div class="col-4-lg offset-lg-1">
          <div v-on:click="selectKindOfProducts(1)" class="btn btn_products">Svatba</div>
        </div>
        <div class="col-4-lg offset-lg-1">
          <div v-on:click="selectKindOfProducts(2)" class="btn btn_products">Oslava</div>
        </div>
        <div class="col-4-lg offset-lg-1">
          <div v-on:click="selectKindOfProducts(3)" class="btn btn_products">Vánoce</div>
        </div>
      </div>
      <app-product-row v-for="(product, index) in selectedProducts" v-bind:key="product.label" v-bind:product="product"
                       v-bind:is-left="checkConditions(index)"></app-product-row>
    </div>
  </div>
</template>

<script>
// import entrance from "./entrance";
import ProductRow from "@/components/ProductRow";

export default {
  name: "Products",
  components: {
    'app-product-row': ProductRow
  },
  data() {
    return {
      kindOfSelect: 0,
      leftRowProduct: false,
      products: [
        {
          kind: [0],
          label: 'Koláč ze skanzenu',
          srcImg: "../productsFoto/kolace_skanzen.jpg",
          text: 'Velký koláč plný tvarohu uvnitř. Navrchu jsou zpravidla povidla a křupavá posýpka. Během sezóny přidáváme i čerstvé ovoce z naší zahrádky jako jsou švestky a meruňky. ' +
              'Koláč ze skanzenu - regionální potravina roku 2020, zlatá chuť jižní Moravy'
        },
        {
          kind: [0, 1],
          label: 'Svatební koláčky z převalovaného těsta',
          srcImg: "../productsFoto/kolacky_svatebni.jpg",
          text: 'Malé koláčky s křupavou posypkou navrchu. Náplně koláčků mohou být různé např. tvaroh s rozinkami, povidla s mákem nebo ořechová náplň.'
        },
        {
          kind: [0],
          label: 'Máslové koláčky',
          srcImg: "../productsFoto/kolace_maslove.jpeg",
          text: 'Středně velké koláčky z máslového těsta. Náplň lze vybírat stejně jako u svatebních koláčků.'
        },
        {
          kind: [0, 3],
          label: 'Linecké',
          srcImg: "../productsFoto/linecke.jpg",
          text: 'Naše linecké kytičky se liší od těch „běžných“ především svou tloušťkou a tím pádem i bohatší chutí. Plněné malinovou marmeládou.'
        },
        {
          kind: [0],
          label: 'Slovácké šátečky',
          srcImg: "../productsFoto/satecky_slovacke.jpg",
          text: 'Šátečky z jogurtového těsta plněné švestkovými povidly a obaleny ve skořicovém cukru.'
        },
        {
          kind: [0],
          label: 'Terezičky',
          srcImg: "../productsFoto/terezicky.jpg",
          text: 'Jsou kuličky vyrobeny z ovesných vloček, medu, brusinek, čokolády a ořechů.  Oceněny jako „Zlatá chuť jižní Moravy“ ' +
              'Terezičky- zlatá chuť jižní Moravy'
        },
        {
          kind: [0],
          label: 'Madlenky',
          srcImg: "../productsFoto/IlustracniFoto.jpg",
          text: 'Vláčné kokosové kuličky'
        },
        {
          kind: [0],
          label: 'Pudinkové',
          srcImg: "../productsFoto/pudinkove.jpg",
          text: 'Kostičky z pudinkového těsta plněné meruňkovou marmeládou a polité čokoládou'
        },
        {
          kind: [0, 3],
          label: 'Oříšky',
          srcImg: "../productsFoto/IlustracniFoto.jpg",
          text: 'Cukroví ve tvaru oříšku slepované krémem a obaleno v kokosu.'
        },
        {
          kind: [0, 3],
          label: 'Ořechové rohlíčky',
          srcImg: "../productsFoto/rohlicky_vanilkove.jpg",
          text: 'Vytlačované rohlíčky obalené v moučkovém cukru.'
        },
        {
          kind: [0, 3],
          label: 'Marokánky',
          srcImg: "../productsFoto/marokanky.jpg",
          text: 'Placky plné ovoce a ořechů potřené belgickou čokoládou.'
        },
        {
          kind: [0, 3],
          label: 'Griliášky',
          srcImg: "../productsFoto/grilias.jpg",
          text: 'Oplatka s karamelovo-ořechovou náplní.'
        },
        {
          kind: [0, 3],
          label: 'Zámecká kostka',
          srcImg: "../productsFoto/kostka_zamecka.jpg",
          text: 'Vláčná buchta obalena v čokoládě a kokosu.'
        },
        {
          kind: [0],
          label: 'Ořechové trubičky',
          srcImg: "../productsFoto/trubicky_orechove.jpg",
          text: 'S vanilkovou nebo čokoládovou náplní.'
        },
        {
          kind: [0],
          label: 'Šlehačkové trubičky',
          srcImg: "../productsFoto/trubicky_slehackove.jpg",
          text: 'S vanilkovou nebo čokoládovou náplní.'
        },
        // {
        //   kind: [0, 1, 2, 3],
        //   label: 'Ořechové rohlíčky',
        //   srcImg: "../productsFoto/IlustracniFoto.jpg",
        //   text: 'S vanilkovou nebo čokoládovou náplní.'
        // },
        {
          kind: [0],
          label: 'Laskonky',
          srcImg: "../productsFoto/laskonky.jpg",
          text: 'Křupavý korpus plněný karamelovým krémem.'
        },
        {
          kind: [0, 1],
          label: 'Věnečky',
          srcImg: "../productsFoto/venecek.jpg",
          text: 'Klasické věnečky s vanilkovým krémem a citrónovou polevou.'
        },
        {
          kind: [0, 1],
          label: 'Pomerančový řez',
          srcImg: "../productsFoto/rez_pomeranc.jpg",
          text: 'Karamelový, Citrónový, Punčový, Čokoládový, Kávový, Izidor, Ořechový, Vracovské hrby, Pařížský'
        },
        {
          kind: [0, 1],
          label: 'Čokoládový řez',
          srcImg: "../productsFoto/rez_cokolada.jpg",
          text: 'Karamelový, Citrónový, Punčový, Čokoládový, Kávový, Izidor, Ořechový, Vracovské hrby, Pařížský'
        },
        {
          kind: [0, 1],
          label: 'Karamelový řez',
          srcImg: "../productsFoto/rez_karamel.jpg",
          text: 'Karamelový, Citrónový, Punčový, Čokoládový, Kávový, Izidor, Ořechový, Vracovské hrby, Pařížský'
        },
        {
          kind: [0, 1],
          label: 'Punčových řez',
          srcImg: "../productsFoto/rez_punc.jpg",
          text: 'Karamelový, Citrónový, Punčový, Čokoládový, Kávový, Izidor, Ořechový, Vracovské hrby, Pařížský'
        },
        {
          kind: [0, 1],
          label: 'Izidor',
          srcImg: "../productsFoto/rez_izidor.jpg",
          text: 'Karamelový, Citrónový, Punčový, Čokoládový, Kávový, Izidor, Ořechový, Vracovské hrby, Pařížský'
        },
        {
          kind: [0, 2],
          label: 'Rolády',
          srcImg: "../productsFoto/IlustracniFoto.jpg",
          text: 'Karamelová, Čokoládová, Vanilková, Pařížská'
        },
        {
          kind: [0],
          label: 'Slané',
          srcImg: "../productsFoto/slane.jpg",
          text: 'Slané tyčinky, škvarkové placky – vhodné především k pivu a vínu\n'
        },
      ],
      selectedProducts: []
    }
  },
  methods: {
    getWidth: function () {
      if (window.screen.width < 992) {
        console.log(window.screen.width)
        return false
      }
      else {
        return true
      }
    },
    checkConditions: function (index) {
      if (this.getWidth() === true) {
        if (index % 2 === 0) {
          return true
        }
        else {
          return false
        }
      }
      else {
        return false
      }

    },
    selectKindOfProducts: function (kind) {
      this.kindOfSelect = kind
      let x
      let i
      this.selectedProducts = []
      for (x in this.products) {
        for (i in this.products[x].kind) {
          if (this.products[x].kind[i] === this.kindOfSelect) {
            this.selectedProducts.push(this.products[x])
          }
        }
      }
    },
  },
  created() {
    this.selectKindOfProducts(0)
    this.getWidth()
  }
}
</script>

<style scoped>
.backgroundProducts {
  background-image: url("../assets/backgroundProducts.jpg");
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
  width: 100%;
  background-repeat: no-repeat;
}
.blockOfProducts {
  margin-top: 0em;
  min-height: 100vh;
  padding-top: 3em;
  padding-bottom: 3em;
  background-color: #ffffffd1;
}
@media screen and (max-width: 575px){
  .paddingLeft {
    padding-left: 5em;
  }
}
@media screen and (max-width: 500px){
  .paddingLeft {
    padding-left: 4em;
  }
}
@media screen and (max-width: 420px){
  .paddingLeft {
    padding-left: 3em;
  }
}
@media screen and (max-width: 380px){
  .paddingLeft {
    padding-left: 2.5em;
  }
}
@media screen and (max-width: 327px){
  .paddingLeft {
    padding-left: 1em;
  }
}


.btn_products:hover {
  color: #fff;
  background-color: rgba(45, 41, 41, 0.99);
  border-color: rgba(45, 41, 41, 0.99);
}

.btn_products {
  color: black;
  background-color: rgba(255, 255, 255, 0.85);
  border-color: rgba(45, 41, 41, 0.99);
}

hr {
  margin-left: 12em;
  margin-right: 12em;
  border-top: 1px solid black;
}


</style>