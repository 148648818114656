import HomePage from "./components/HomePage";
import Products from "./components/Products";

export default [
    { path: '/', component: HomePage },
    { path: '/products', component: Products},
    { path: '/contact', redirect: '/' },
    { path: '/reference', redirect: '/' },
    { path: '/aboutus', redirect: '/' }

]