<template>
    <div class="wrapper">
        <header id="primary">

            <img class="img-fluid mx-auto d-block" src="../assets/foto_top_two.jpg" alt="Harnošovi">
        </header>
            <app-entrance></app-entrance>

    </div>
</template>

<script>
    import entrance from "./entrance";
    export default {
        name: "navbar",
        components: {
            'app-entrance': entrance
        }
    }
</script>

<style scoped>

</style>