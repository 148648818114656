<template>
    <div>
        <nav id="top-navbar">
            <div class="menu-icon">
                <i class="fas fa-bars"></i>
            </div>
            <div class="logo">
            </div>
            <div class="menu">
                <ul>
                    <li><a class="text-upper" href="#aboutus">O nás</a></li>
                    <li><a class="text-upper" href="#products">Produkty</a></li>
                    <li><a class="text-upper" href="#reference">Reference</a></li>
                    <li><a class="text-upper" href="#contact">Kontakt</a></li>
                </ul>
              <hr>
            </div>
        </nav>
    </div>
</template>

<script>
    export default {
        name: "entrance"
    }
</script>

<style scoped>
    hr {
      margin: 15px 36.3em 2em 37em;
      border-top: 1px solid #2b2a2a;
    }
    a:hover {
        color: #848488;
    }
    .logo {
        line-height: 80px;
        /*position: fixed;*/
        float: left;
        margin: 0px 70px;
        color: #fff;
        font-weight: bold;
        font-size: 20px;
        letter-spacing: 2px;
    }

    nav {
        /*position: fixed;*/
        width: 100%;
        line-height: 60px;
        z-index: 1;
    }

    nav ul {
        line-height: 60px;
        list-style: none;
        background-color: #fffffff5;
        overflow: hidden;
        padding: 0;
        text-align: center;
        margin: 0;
        transition: 1s;
    }

    nav.black ul {
        background: #000;
    }

    nav ul li {
        display: inline-block;
        padding: 10px 40px;
        font-weight: 900;
    }

    nav ul li a {
        text-decoration: none;
        color: black;
        font-size: 16px;
    }

    .menu-icon {
        line-height: 60px;
        width: 100%;
        background: #000;
        text-align: right;
        box-sizing: border-box;
        padding: 15px 24px;
        cursor: pointer;
        color: #fff;
        display: none;
    }
    .fas {
        font-weight: 900;
        font-size: 50px;
        vertical-align: middle;
    }
    @media(max-width: 976px) {

        .logo {
            display: none;
            position: fixed;
            top: 0;
            margin-top: 16px;
        }

        nav ul {
            max-height: 0px;
            background: #000;
        }

        nav.black ul {
            background: #000;
        }

        nav ul li {
            box-sizing: border-box;
            width: 100%;
            padding: 4px;
            text-align: center;
        }

        .menu-icon {
            display: block;
        }

    }
    header {
        margin-bottom: -0.8em;
    }
    header a:hover {
        text-decoration: none;
    }
    /*===============-------VIDEO------==============*/
    header {
        background-size: cover;
        background-position: center;
        height: 100vh;
    }
    /*===============-------RESPONZIV------==============*/
    @media(min-width: 976px) {
        header, #primary {
            background: none;
            height: auto;
        }
    }
    @media screen and (max-width: 976px){
        #top-navbar {
            display: none;
        }
    }
</style>