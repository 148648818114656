<template>
    <div style="padding-bottom: 3em" class="container">
        <h1 class="text-center pt-lg-5 pb-lg-4 pt-3 pb-3">Reference</h1>
        <div class="col-lg backUnderReferenc">
            <a v-on:click="prevSlide()" class="prev">❮</a>
            <a v-on:click="nextSlide()" class="next">❯</a>
            <div>
                <div id="SlideOne" class="container text-center">
                    <h2 class="pt-lg-4">{{this.slides[this.counter].label}}</h2>
                    <div class="row pt-lg-4">
                        <div class="col-lg-4 offset-lg-2 pb-lg-4">
                            <a :href="this.slides[this.counter].href"><img class="img-fluid" :src="this.slides[this.counter].src" alt="Reference"></a>
                        </div>
                        <div class="col-lg-4 text-lg-left">
                            <p class="positionTextBlok">{{this.slides[this.counter].text}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        name: "referenc",
        data() {
            return {
                counter: 0,
                slides: [
                    {label: 'Herbář - Česká televize', src:"/slider/lightBoxFive.jpg", href:"https://www.ceskatelevize.cz/porady/10614805433-herbar/?fbclid=IwAR0szV_A6z_5a5LWKQAKu5o2YobSnquyl7lmCaCAEioz2Iwfp6w2OnoDq2w", text: "Drazí, o prázdninách se nám podařilo natočit krátkou reportáž o našem cukrářství do pořadu HERBÁŘ. Myslíme si, že se to báječně vydařilo a máme z toho opravdu velkou radost!"},
                ]
            }
        },
        methods: {
            nextSlide() {
                this.counter = (this.counter + 1) % this.slides.length;
            },
            prevSlide() {
                this.counter = (this.slides.length + this.counter - 1) % this.slides.length;
            }
        },
    }
</script>

<style scoped>
    .prev,
    .next {
        cursor: pointer;
        position: absolute;
        top: 47%;
        width: auto;
        padding: 16px;
        color: black;
        font-weight: bold;
        font-size: 18px;
        border-radius: 0 3px 3px 0;
        user-select: none;
    }
    @media screen and (max-width: 420px){
        .prev,
        .next {
            top: 62%;
            padding: 0;
        }
    }

    .next {
        right: 0;
        margin-right: 10px;
        border-radius: 3px 0 0 3px;
    }
    .backUnderReferenc {
        background-color: rgba(255, 255, 255, 0.5);
    }
    .positionTextBlok {
        padding-left: 26px;
        padding-right: 20px;
    }

</style>