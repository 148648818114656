<template>
  <div class="container pt-lg-4 pb-lg-3">
    <h1 class="text-center pt-3">Kontakt</h1>
    <div class="row">
      <div class="col-lg-12">
        <div id="icons" class="row pt-lg-5 pb-lg-4">
          <div class="col-lg-3 pt-3 text-center">
            <img class="phone AnimationOne" src="../assets/phone.svg" alt="Phone">
            <p class="iconSource pt-lg-4">+ 420 737 525 600</p>
            <p class="iconSource">+ 420 608 584 456</p>
          </div>
          <div class="col-lg-3 pt-3 text-center">
            <img class="mail AnimationOne" src="../assets/mail.svg" alt="Mail">
            <p class="iconSource pt-lg-4"><a href="mailto:katerina.harnosova@seznam.cz">katerina.harnosova@seznam.cz</a></p>
            <p class="iconSource"><a href="mailto:harnos.radek@seznam.cz">harnos.radek@seznam.cz</a></p>
          </div>
          <div class="col-lg-3 pt-3 text-center">
            <img class="facebook AnimationOne" src="../assets/facebook.svg" alt="Facebook">
            <p class="iconSource pt-lg-4"><a href="https://www.facebook.com/Peka%C5%99stv%C3%AD-cukr%C3%A1%C5%99stv%C3%AD-Martina-Harno%C5%A1ov%C3%A1-977952635685888">Facebook</a>
            </p>
          </div>
          <div class="col-lg-3 pt-3 text-center">
            <img class="instagram AnimationOne" src="../assets/home.svg" alt="Adress">
            <p class="iconSource pt-lg-4"><a href="https://www.google.com/maps/place/Okru%C5%BEn%C3%AD+275,+696+42+Vracov/data=!4m2!3m1!1s0x4713237cab7dd8f9:0x8785be8abeee60f1?sa=X&ved=2ahUKEwi89qbJgcfuAhWxmIsKHdp6AWkQ8gEwAHoECAUQAQ">Okružní 275,
              Vracov, 696 42</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "contact"
}
</script>

<style scoped>
p {
  margin-bottom: 0.1em;
}

a {
  color: black;
}

.btn_contact:hover {
  color: #fff;
  background-color: rgba(95, 92, 92, 0.99);
  border-color: rgba(95, 92, 92, 0.99);
}

.btn_contact {
  color: black;
  background-color: rgba(255, 255, 255, 0.85);
  border-color: rgba(95, 92, 92, 0.99);
}

#icons .phone, .mail, .facebook, .instagram {
  width: 20%;
}

#icons1 .phone, .mail, .facebook, .instagram {
  width: 20%;
}

@media screen and (min-width: 1100px){
  .phone:hover {
    animation-name: animation1;
    animation-duration: 1s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
  }
  @keyframes AnimationOne {
    0% {
      transform: translateY(0px);
    }
    100% {
      transform: translateY(10px);
    }
  }
}

</style>