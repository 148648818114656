<template>
  <div>
    <div class="row no-gutters" v-if="this.isLeft">
      <div class="col-lg-5 offset-lg-1 img-hover-zoom">
        <img class="img-fluid" :src="this.product.srcImg" alt="Koláče">
      </div>
      <div class="col-lg-5 pl-lg-4 pt-lg-4">
        <h2 class="pt-3 text-center">{{ this.product.label }}</h2>
        <p class="text-justify pb-1">{{ this.product.text }}</p>
      </div>
    </div>
    <div class="row no-gutters" v-if="!this.isLeft">
      <div class="col-lg-5 offset-lg-1 pt-lg-4 pr-lg-4">
        <h2 class="pt-3 text-center">{{ this.product.label }}</h2>
        <p class="text-justify pb-1">{{ this.product.text }}</p>
      </div>

      <div class="col-lg-5 img-hover-zoom">
        <img class="img-fluid" :src="this.product.srcImg" alt="Linecké">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['product', 'isLeft'],
  name: "ProductRow",
  data() {
    return {
    }
  },
}
</script>

<style scoped>
hr {
  margin-left: 12em;
  margin-right: 12em;
  border-top: 1px solid black;
}
.btn_products:hover {
  color: #fff;
  background-color: rgba(45, 41, 41, 0.99);
  border-color: rgba(45, 41, 41, 0.99);
}

.btn_products {
  color: black;
  background-color: rgba(255, 255, 255, 0.85);
  border-color: rgba(45, 41, 41, 0.99);
}
/*@media (min-width: 900px) {
  .img-hover-zoom {
    overflow: hidden;
  }

  .img-hover-zoom img {
    transform-origin: 50% 65%;
    transition: transform 5s, filter 3s ease-in-out;
  }


  .img-hover-zoom:hover img {
    transform: scale(3);
  }
 }
*/


</style>