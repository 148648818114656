<template>
    <div class="container">
        <h1 class="text-center pt-lg-3 pb-lg-4 pt-3">Pekařství a cukrářství Harnošovi</h1>
        <div class="row no-gutters">
            <div class="col-lg-6">
                <div class="col-lg-10 offset-lg-1">
                    <p class="text-center text-lg-right AboutUsTextleft">Před osmi lety jsme se rozhodli přestavět naši starou
                      zahradní garáž na malou rodinou pekárničku. Ze začátku to vypadalo jako šílený nápad, ale sen se stal skutečností.
                      Původně pocházím z Karlových Varů a na jižní Moravu jsem se přestěhovala, když mi bylo jedenáct let.
                      Odmalička mě lákalo všechno, co bylo spojeno s jídlem, pečením a vařením.
                      K pečení jako takovému mě přivedla až moje tchýně,
                      která je mojí inspirací doteď.
                      Pamatuji si, že jsem s nadšením sledovala její cukrářský um
                      a&nbsp;chtěla to umět stejně jako ona.</p>
                </div>
            </div>
            <div class="col-lg-6">
                <img class="img-fluid" src="../assets/harnos_right.jpg" alt="Harnoš">
            </div>
        </div>
        <div class="row no-gutters">
            <div class="col-lg-6">
                <img class="img-fluid" src="../assets/harnos_left.jpg" alt="Harnoš">
            </div>
            <div class="col-lg-6">
                <div class="col-lg-10 offset-lg-1">
                    <p class="text-center text-lg-left AboutUsTextright">
                      První vánoční objednávky jsme pekly spolu v naší malinké kuchyni, kde se nebylo možno hnout.
                      To mi vnuklo myšlenku přestěhovat se do něčeho většího. Po dlouhém rozhodování jsem se se svým mužem rozhodla
                      přestavět naši garáž na rodinou pekárničku.
                      Musím říct, že to bylo jedno z nejlepších rozhodnutí v mém životě.
                      Fungujeme už více než sedm let a není většího potěšení, než když se sejde celá rodina „na koláčky“,
                      jak se tomu u nás říká a společně brzy ráno připravujeme koláčky pro naše zákazníky, aby byly ještě teplé.
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "aboutUs"
    }
</script>

<style scoped>
    .AboutUsTextleft {
        font-size: 19px;
        padding-top: 0.5em;
    }
    .AboutUsTextright {
        font-size: 19px;
        padding-top: 1.5em;
    }

</style>