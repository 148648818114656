<template>
  <div>
    <app-nav class="pb-lg-4">
    </app-nav>
    <app-aboutus id="aboutus" class="pb-lg-4"></app-aboutus>
    <app-products id="products" class="pb-lg-4"></app-products>
    <div class="container-new">
      <video autoplay muted loop id="myVideo">
        <source src="@/assets/Main_3.mp4" type="video/mp4">
      </video>
      <div onclick="window.location.href='#/products'" v-on:click="scrollTop" class="player-buttons btn btn-lg btn-outline-light">Prohlídka produktů</div>
    </div>
    <div id="reference" class="imgReference pb-lg-3">
      <app-referec></app-referec>
    </div>
    <app-contact id="contact"></app-contact>
    <footer>
      <div class="footer-copyright text-center py-3">© 2021 Copyright:
        <a href="https://dokamar.cz/">Dokamar</a>
      </div>
    </footer>
    <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2618.8618310856027!2d17.209454315905937!3d48.97515450043171!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4713237cab7dd8f9%3A0x8785be8abeee60f1!2sOkru%C5%BEn%C3%AD%20275%2C%20696%2042%20Vracov!5e0!3m2!1scs!2scz!4v1612033464518!5m2!1scs!2scz"
        width="100%" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false"
        tabindex="0"></iframe>
  </div>
</template>


<script>
import navbar from "./navbar";
import aboutUs from "./aboutUs";
import referenc from "./referenc";
import contact from "./contact";
import ourproducts from "@/components/ourproducts";

export default {
  name: "HomePage",
  components: {
    'app-nav': navbar,
    'app-aboutus': aboutUs,
    'app-referec': referenc,
    'app-contact': contact,
    'app-products': ourproducts
  },
  data() {
    return {}
  },
  methods: {
    scrollTop: function() {
      window.scrollTo(0, 0);
    }
  }
}
</script>

<style scoped>
a {
  color: black;
}

#myVideo {
  object-fit: cover;
  width: 100vw;
  height: 60vh;
}

.container-new {
  position: relative;
  margin-bottom: -7px;
}

.container-new > .player-buttons {
  left: 47%;
  margin: -64px 0 0 -64px;
  position: absolute;
  top: 50%;
  z-index: 1;
}

/*
.imgProducts {
  background-image: url("../assets/img-1.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  height: 50vh;
  background-position: center;
}

@media screen and (max-width: 700px){
  .imgProducts {
    background-attachment: scroll;
    height: 31vh;
  }
}
*/
@media screen and (min-width: 1300px) and (max-width: 1600px) {
  .padingTop {
    padding-top: 224vh;
    position: absolute;
  }
}

@media screen and (min-width: 1920px) {
  .padingTop {
    padding-top: 224vh;
    position: absolute;
  }
}

@media (max-width: 330px) {
  .container-new > .player-buttons {
    left: 25%;
  }

}

@media screen and (min-width: 331px) and (max-width: 375px) {
  .container-new > .player-buttons {
    left: 28%;
  }
}

@media screen and (min-width: 376px) and (max-width: 415px) {
  .container-new > .player-buttons {
    left: 31%;
  }

}

.imgReference {
  background-image: url("../assets/backgournd.jpg");
  background-size: cover;
  height: auto;
  background-position: center;
}
</style>